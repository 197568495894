<template>
  <v-chip-group
    v-model="value"
    v-dragscroll.x
    v-bind="chipGroupProps"
    class="px-4 px-md-0 chips-bar"
  >
    <slot>
      <v-chip
        v-for="item in items"
        v-bind="item"
        :key="item.value"
        class="bg-background"
        :class="{
          'text-primary v-chip--selected': value === item.value
        }"
        @click="emit('click:item', item.value)"
      />
    </slot>
  </v-chip-group>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import { dragscroll as vDragscroll } from 'vue-dragscroll'

// NOTE: компонент решает один из багов Vuetify
//     - при рендере на сервере не устанавливается активный чип

const value = defineModel({
  type: [String, Number, Array] as PropType<string | number | Array<string | number>>,
})

defineProps({
  chipGroupProps: { type: Object as PropType<Record<string, unknown>>, default: () => ({}) },
  items: {
    type: Array as PropType<Array<{ value: string | number, text: string, to?: RouteLocationRaw }>>,
    default: () => [],
  },
})

const emit = defineEmits(['click:item'])
</script>

<style lang="scss" scoped>
.chips-bar {
  z-index: 1;
  position: relative;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & :deep(.v-slide-group__container) {
    flex-shrink: 0;
    overflow: auto;
  }
}
</style>
